.wrapper {
    min-height: rem(900px);
    background-size: cover;
    background-image: url('/images/banner3.jpg');
}
  
  .form {
    border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-7));
    min-height: rem(900px);
    max-width: rem(450px);
    padding-top: rem(80px);
  
    @media (max-width: $mantine-breakpoint-sm) {
      max-width: 100%;
    }
  }
  
  .title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
  }