.AuthenticationImage_wrapper__4yNEz {
    min-height: calc(56.25rem * var(--mantine-scale));
    background-size: cover;
    background-image: url('/images/banner3.jpg');
}
  
  .AuthenticationImage_form__Uf56c {
    border-right: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  }
  
  [data-mantine-color-scheme='dark'] .AuthenticationImage_form__Uf56c {
    border-right: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-7);
}
  
  .AuthenticationImage_form__Uf56c {
    min-height: calc(56.25rem * var(--mantine-scale));
    max-width: calc(28.125rem * var(--mantine-scale));
    padding-top: calc(5rem * var(--mantine-scale));
}
  
  @media (max-width: 48em) {
  
  .AuthenticationImage_form__Uf56c {
      max-width: 100%
  }
    }
  
  .AuthenticationImage_title__fsrgw {
    color: var(--mantine-color-black);
  }
  
  [data-mantine-color-scheme='dark'] .AuthenticationImage_title__fsrgw {
    color: var(--mantine-color-white);
}
  
  .AuthenticationImage_title__fsrgw {
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
}
.LoginNav_header__sseMa {
    height: calc(3.75rem * var(--mantine-scale));
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
  }
    [data-mantine-color-scheme='dark'] .LoginNav_header__sseMa {
    border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}
  
  .LoginNav_link__EMXbE {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: var(--mantine-color-black);
  }
  
  [data-mantine-color-scheme='dark'] .LoginNav_link__EMXbE {
    color: var(--mantine-color-white);
}
  
  .LoginNav_link__EMXbE {
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
}
  
  @media (max-width: 48em) {
  
  .LoginNav_link__EMXbE {
      height: calc(2.625rem * var(--mantine-scale));
      width: 100%
  }
    }
  
  @media (hover: hover) {
    .LoginNav_link__EMXbE:hover {
      background-color: var(--mantine-color-gray-0);
    }
      [data-mantine-color-scheme='dark'] .LoginNav_link__EMXbE:hover {
      background-color: var(--mantine-color-dark-6);
    }
}
  
  @media (hover: none) {
    .LoginNav_link__EMXbE:active {
      background-color: var(--mantine-color-gray-0);
    }
      [data-mantine-color-scheme='dark'] .LoginNav_link__EMXbE:active {
      background-color: var(--mantine-color-dark-6);
    }
}
  
  .LoginNav_subLink__lP4wj {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
  }
  
  @media (hover: hover) {
    .LoginNav_subLink__lP4wj:hover {
      background-color: var(--mantine-color-gray-0);
    }
      [data-mantine-color-scheme='dark'] .LoginNav_subLink__lP4wj:hover {
      background-color: var(--mantine-color-dark-7);
    }
}
  
  @media (hover: none) {
    .LoginNav_subLink__lP4wj:active {
      background-color: var(--mantine-color-gray-0);
    }
      [data-mantine-color-scheme='dark'] .LoginNav_subLink__lP4wj:active {
      background-color: var(--mantine-color-dark-7);
    }
}
  
  .LoginNav_dropdownFooter__ix7J6 {
    background-color: var(--mantine-color-gray-0);
  }
  
  [data-mantine-color-scheme='dark'] .LoginNav_dropdownFooter__ix7J6 {
    background-color: var(--mantine-color-dark-7);
}
  
  .LoginNav_dropdownFooter__ix7J6 {
    margin: calc(var(--mantine-spacing-md) * -1);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-1);
}
  
  [data-mantine-color-scheme='dark'] .LoginNav_dropdownFooter__ix7J6 {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-5);
}
